import GraperVariety from './GrapeVariety'
import Region from './Region'
import Winery from './Winery'
import Classification from './Classification'
import Land from './Land'
import WineType from './WineType'
import Category from './Category'

export default class Wine {
	constructor() {
		this.id = null
		this.title = null
		this.region = new Region()
		this.venue = null
		this.articleNr = null
		this.winery = new Winery()
		this.classification = new Classification()
		this.grapeVariety = new GraperVariety()
		this.land = new Land()
		this.wineType = new WineType()
		this.image = null
		this.category = new Category()
	}
}